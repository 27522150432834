@import '../../../node_modules/@angular/material/prebuilt-themes/indigo-pink.css';

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import '~ngx-toastr/toastr';
@import '~quill/dist/quill.bubble.css';
// or
@import '~quill/dist/quill.snow.css';

html {
  scroll-behavior: smooth;
}

.public-container {
  @apply container;
  @screen xl {
    max-width: 1152px;
  }

  @screen 2xl {
    max-width: 1200px;
  }
}

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.section-wrapper {
  @apply flex flex-col h-full w-full lg:overflow-hidden;
}

.section-content {
  @apply pt-4 px-5 md:px-7 lg:flex lg:px-10 max-w-full lg:overflow-hidden;

  @screen lg {
    min-height: calc(100vh - 120px);
  }

  .left-section {
    @apply w-full h-full lg:pr-3 lg:mr-3 lg:max-w-md lg:overflow-y-auto xl:max-w-[585px];
  }

  .right-section {
    @apply flex-1 h-full pl-5 py-4 lg:border-l lg:border-solid lg:border-[#E3E5EB] lg:overflow-y-auto;
  }
}

.generate-ai-form {
  .field-label {
    @apply py-[2px] text-lg leading-xl;
    margin-bottom: -0.5rem;
  }
}

.ai-projects-page-nav-heading {
  @apply w-full flex justify-between items-center px-3 py-2 border-b border-gray-100;
  @media screen and (max-width: 280px) {
    @apply flex-col items-start gap-3;
  }
}

.ai-pricing-check-list {
  @apply list-none flex flex-col gap-y-1;
  & li {
    @apply text-lg font-normal leading-xl flex gap-x-1;

    &::before {
      @apply text-blue-100 text-2xl leading-2.5xl font-medium;
      content: url('/assets/images/pages/home/check.svg');
    }
  }
}

u-input.textarea-placeholder-sibling + div {
  @apply transition ease-in-out duration-300;
}

u-input.textarea-placeholder-sibling:has(textarea:focus) + div {
  @apply opacity-40;
}

.asr-container {
  @screen lg {
    max-width: 800px;
  }
}
.amber {
  @apply bg-yellow-100;
}
